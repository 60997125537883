import { Component, OnInit } from '@angular/core';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs'
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})



export class AppComponent implements OnInit {
  title = 'intrafund';

  constructor(private afs: AngularFirestore, public auth: AuthService) { }

  // search stuff.
  public model: any;
  public terms;
  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map((term: String) => term.length < 2 ? []
      : this.terms.filter(v => v.title.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )
  
  formatter = (x: { title: string }) => {
    return x.title;
  }
  ngOnInit() {
    this.afs.collection('searchTerms').valueChanges().subscribe(d => {
      this.terms = d;
    })
  }
}
