import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root'
})
export class PreloadOfferGuard implements Resolve<any> {
  constructor(public afs: AngularFirestore, private router: Router){}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const readableId = next.paramMap.get('offerName')
    return this.afs.collection('offers', ref => ref.where('readableId', '==', readableId))
      .valueChanges()
      .pipe(
        first(),
        tap(offer => {
          if (offer.length < 1) {
            this.router.navigate(['not_found']);
          }
        }));
  }
}
