import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {
  public offer;
  constructor(public titleService: Title, public route: ActivatedRoute, public afs: AngularFirestore) {
    this.titleService.setTitle('iFundAlaska - Little Brown Bird Baking Co.')
   }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.offer = data[0][0]
    })
  }

}
