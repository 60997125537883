import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OfferComponent } from './offer/offer.component';
import { OfferProgressComponent } from './offer/offer-progress/offer-progress.component';
import { OfferExecutiveSummaryComponent } from './offer/offer-executive-summary/offer-executive-summary.component';
import { OfferTabsComponent } from './offer/offer-tabs/offer-tabs.component';
import { OfferAnnouncementsComponent } from './offer/offer-announcements/offer-announcements.component';
import { HomeComponent } from './home/home.component';
import { OfferTimelineComponent } from './offer/offer-timeline/offer-timeline.component';
import { OfferDocumentsComponent } from './offer/offer-documents/offer-documents.component';
import { OfferDetailsComponent } from './offer/offer-details/offer-details.component';
import { OfferSpecificsComponent } from './offer/offer-specifics/offer-specifics.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestore, FirestoreSettingsToken } from '@angular/fire/firestore';
import { Notfound404Component } from './error-pages/notfound404/notfound404.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';

import { AuthService } from './services/auth.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { LoginComponent } from './auth/login/login.component';
import { UserProfileComponent } from './auth/user-profile/user-profile.component';


@NgModule({
  declarations: [
    AppComponent,
    OfferComponent,
    OfferProgressComponent,
    OfferExecutiveSummaryComponent,
    OfferTabsComponent,
    OfferAnnouncementsComponent,
    HomeComponent,
    OfferTimelineComponent,
    OfferDocumentsComponent,
    OfferDetailsComponent,
    OfferSpecificsComponent,
    Notfound404Component,
    LearnMoreComponent,
    LoginComponent,
    UserProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  providers: [AngularFirestore,{ provide: FirestoreSettingsToken, useValue: {} }, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
