import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
  selector: 'app-offer-tabs',
  templateUrl: './offer-tabs.component.html',
  styleUrls: ['./offer-tabs.component.css']
})
export class OfferTabsComponent implements AfterViewInit {
  @Input() details: String;
  public viewLoaded = false;
  @ViewChild('tabs') public tabs: NgbTabset;
  constructor(public router: Router) {
   }

  ngAfterViewInit() {
    this.viewLoaded = true;
    // setTimeout(()=> {this.tabs.select("documents-tab")}, 500)
  }

}
