import { Component, OnInit, Input } from '@angular/core';
import { timestamp } from 'rxjs/operators';

@Component({
  selector: 'app-offer-executive-summary',
  templateUrl: './offer-executive-summary.component.html',
  styleUrls: ['./offer-executive-summary.component.css']
})


export class OfferExecutiveSummaryComponent implements OnInit {
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() max: Number;
  @Input() min: Number;
  @Input() goal: Number;
  @Input() type: String;  
  @Input() images: Array<String>;
  now;
  daysUntil;
  // images = [1, 2, 3, 4].map(() => `https://picsum.photos/1200/400?random&t=${Math.random()}`);

  constructor() { }

  ngOnInit() {
    this.now = Math.floor(Date.now() / 1000)
    this.daysUntil = Math.floor((this.endDate.seconds - this.now) / 86400);
  }

}
