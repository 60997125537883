import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-offer-announcements',
  templateUrl: './offer-announcements.component.html',
  styleUrls: ['./offer-announcements.component.css']
})
export class OfferAnnouncementsComponent implements OnInit {
  @Input() announcements: Array<String>;
  constructor() { }

  ngOnInit() {
    // console.log(this.announcements)
  }

}
