import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-offer-progress',
  templateUrl: './offer-progress.component.html',
  styleUrls: ['./offer-progress.component.css']
})
export class OfferProgressComponent implements OnInit {
  @Input() goal: number;
  @Input() progress: number;
  constructor() { }

  ngOnInit() {
  }

}
