import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfferComponent } from './offer/offer.component';
import { HomeComponent } from './home/home.component';
import { PreloadOfferGuard } from './guards/preload-offer.guard';
import { Notfound404Component } from './error-pages/notfound404/notfound404.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { AuthGuard } from './guards/auth.guard'
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
  {path: "", component: HomeComponent},
  {
    path: "offer/:offerName", 
    component: OfferComponent,
    resolve: [PreloadOfferGuard],
    canActivate: [AuthGuard] 
  },
  {path: "learn-more", component: LearnMoreComponent},
  {path: "not_found", component: Notfound404Component},
  {path: "login", component: LoginComponent},
  {path: "**", component: HomeComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
