import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public offers: Observable<any>;
  public images: Array<string> = [
    "/assets/images/pexels-photo-929245.jpeg",
    "/assets/images/pexels-photo-1267361.jpeg",
    "/assets/images/pexels-photo-1331971.jpeg"
  ]
  constructor(public afs: AngularFirestore) { }

  ngOnInit() {
   this.offers = this.afs.collection("offers").valueChanges().pipe()
   this.offers.subscribe(console.log)
  }

}
